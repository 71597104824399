<template>
  <div v-if="!hasActiveSubscription">
    <h4>{{ $t('notificationsNoSubscriptions') }}</h4>
    <p><button @click.prevent="goToPurchase()" class="btn btn-primary">{{ $t('makePurchase') }}</button></p>
  </div>
  <Form
    @submit="submit"
    ref="settings"
  >
    <div>
      <span class="paymentDetail-param paymentDetail-param_main">{{ $t('notificationSettings.settings_text_1') }}</span>
      <Field
        as="select"
        name="count"
        id="count"
        class="form-control mb-0 form-group d-inline-flex w-auto mx-2"
        v-model="countMessage"
        :value="initForm.count"
        :disabled="!hasActiveSubscription"
      >
        <option
          :key="countMessage"
          :value="countMessage"
          v-for="countMessage of messagesList"
        >{{ countMessage }}</option>
      </Field>
      <span class="paymentDetail-param paymentDetail-param_main">
        {{ $t('notificationSettings.settings_text_2') }}
      </span>
      <Field
        as="select"
        name="action"
        id="action"
        class="form-control mb-0 form-group d-inline-flex w-auto mx-2"
        v-model="typeElement"
        :value="initForm.action"
        :disabled="!hasActiveSubscription"
      >
        <option
          :key="typeElement"
          :value="typeElement"
          v-for="typeElement of typesList"
        >{{ $t(`notificationSettings.${typeElement}`) }}</option>
      </Field>
      <button class="btn btn-primary" :disabled="!hasActiveSubscription" style="margin-top: -3px">{{ $t('apply') }}</button>
    </div>
  </Form>
  <slot></slot>
</template>

<script>
import { Form, Field } from 'vee-validate'
import { useRouter } from 'vue-router'
import { useNotificationSettingsLogic } from '@/application/composables/notificationSettingsLogic.js'

export default {
  name: 'NotificationSettingsForm',
  components: {
    Form,
    Field,
    // hint: defineAsyncComponent(() => import('@/application/components/elements/Hint.vue')),
  },
  props: {
    hasActiveSubscription: {
      type: Boolean,
    },
    initForm: {
      type: Object,
    },
  },
  emits: ['form-change'],
  setup(props, { emit }) {
    const router = useRouter()
    const messagesList = [25, 50, 100, 200, 500, 1000, 2000]
    const typesList = ['send_email_notification', 'auto_charge']

    const {
      submitFormNotificationState,
    } = useNotificationSettingsLogic()

    return {
      goToPurchase: () => {
        router.push({ name: 'purchase' })
      },
      submit: (data) => {
        submitFormNotificationState(data)
        emit('form-change', data)
      },
      messagesList,
      typesList,
    }
  },
}
</script>
